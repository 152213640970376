$text-top-margin: 50px;

iframe {
  display: block;
}

@media (min-width: 1000px) {
  .text-container {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: $text-top-margin;
  }
  .youtube-container {
    justify-content: center;
    margin: 40px auto;
    width: 700px;
    height: 393.75px;
  }
  .image-container {
    justify-content: center;
    margin: 40px auto;
    display: flex;
  }
  .image-container img {
    width: 700px;
    // height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 1000px) {
  .text-container {
    margin-left: 10px;
    align-items: center;
    margin-top: $text-top-margin;
  }
  .youtube-container {
    justify-content: center;
    margin: 0 auto;
    width: 280px;
    height: 157.5px;
  }
  .image-container {
    justify-content: center;
    margin: 0 auto;
    display: flex;
  }
  .image-container img {
    width: 300px;
    // height: 100%;
    object-fit: contain;
  }
}
