.company-link {
  font-weight: bold !important;
}

.profile-image {
  height: 200px;
  border-radius: 30%;
}
.about-header {
  //   margin-top: 100px;
  font-size: 60px;
}

.about-container {
  display: flex;
  float: left;
  //   margin-left: 80px;
}

@media (min-width: 1000px) {
  .about-container {
    display: flex;
    margin-right: 100px;
    margin-left: 80px;
  }
}

@media (max-width: 1000px) {
  .about-container {
    display: block;
    margin-right: 50px;
  }
}

@media (min-width: 1000px) {
  .about-text-container {
    width: 50%;
    margin-left: 80px;
    display: flex;
  }
}

@media (max-width: 1000px) {
  .about-text-container {
    width: 100%;
    display: block;
  }
}

@media (min-width: 1000px) {
  .about-header {
    margin-left: 80px;
  }
  .sub-header {
    margin-left: 80px;
  }
}

.profile-container {
  width: 20%;
}
