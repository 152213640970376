.projects-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.project {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .projects-container {
    // margin-left: -60px;
  }
}
