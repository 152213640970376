@import url("https://fonts.googleapis.com/css?family=Raleway");

$logo-margin: 15px;

.signature-name {
  float: "left";
  width: 70%;
  font-size: 18px;
}

.signature-word {
  float: "left";
  width: 70%;
}

.about-list-item {
  border-bottom: 1px solid black;
  margin-left: 15px;
}

.signature {
  margin-top: 10px;
  font-size: 15px;
  display: flex;
  margin-left: $logo-margin;
  margin-bottom: 40px;
  border-bottom: 1px solid black;
  border-spacing: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: white;
  height: 100%;
  overflow: auto;
  width: 220px;
}

li {
  display: flex;
  color: #000;
  height: 30px;
  justify-content: space-around;
}

li.active {
  background-color: #04aa6d;
  color: white;
}

li:hover:not(.active) {
  background-color: #d1e4e4;
  color: white;
}

a:visited {
  text-decoration: none;
}

.logo-text {
  font-family: "Raleway";
  font-size: 40px;
  margin-left: $logo-margin;
}

.navbar {
  margin-top: 30px;
  // position: fixed;
  float: left;
}

.side-nav {
  margin-top: 0px;
  width: 250px;
  float: left;
  border-right: 1px solid black;
  height: 100%;
  position: fixed;
}

@media (max-width: 1000px) {
  .side-nav {
    display: none;
  }
}
