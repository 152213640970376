$button-highlighted-color: #b6c7aa;

body {
  margin: 0;
  font-family: "Raleway";
}

.header {
  color: black;
  position: fixed;
  top: 0;
  right: 0;
  margin-left: 220px;
  height: 80px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.header-button {
  border-radius: 8px;
  margin: 20px;
  width: 90px;
  height: 30px;
  border: none;
  background-color: #d1e4e4;
  font-size: 17px;
  transition-duration: 0.4s;
}

.header-button:hover {
  background-color: $button-highlighted-color;
}
.container {
  padding: 1px 16px;
  top: 80px;
  z-index: -1;
  float: right;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (max-width: 1000px) {
  .header {
    width: 100%;
  }
  .container {
    width: 90%;
  }
}

@media (min-width: 1000px) {
  .header {
    width: calc(100% - 220px);
  }
  .container {
    width: 80%;
  }
}

a {
  color: black;
  -webkit-text-decoration: dotted;
  text-decoration: dotted;
  width: 100%;
  height: 100%;
  /* align-items: center; */
  display: block;
  display: grid;
  align-content: center;
}
.highlighteda {
  background-color: $button-highlighted-color;
}

.highlightedb {
  background-color: $button-highlighted-color;
}
